import React from "react";
import 'react-toastify/dist/ReactToastify.css';

import { renderToString } from 'react-dom/server';

import { useEffect, useState, useRef } from "react";
import victor from "../image/APROX.svg";
import widthdrow from "../image/widthdrow.png";
import arrow from "../image/cun.svg";
// import Ticon from "../image/usdt.svg"
import infoicon from "../image/info.svg";
import greenarrow from "../image/greenarrow.svg";
// import referr from '../image/referrer.svg'
import {
  useSDK,
  useTokenBalance,
  useContract,
  useAddress,
  useContractRead,
  useContractWrite,
} from "@thirdweb-dev/react";
import { ethers } from "ethers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";

const Deshbord = () => {
  const [USDTAmt, setUSDTAmt] = useState("");
  const [cunAmt, setCunAmt] = useState("");
  const [withdrawAmt, setWithdrawAmt] = useState("");
  const [approveAmt, setApproveAmt] = useState("");
  const [BuyTokenLoading, setBuyTokenLoading] = useState(false);
  const [SellTokensloading, setSellTokensLoading] = useState(false);
  const [WithdrawTokensloading, setWithdrawTokensLoading] = useState(false);
  const [ApproveTokensloading, setApproveTokensLoading] = useState(false);
  const referralLinkRef = useRef(null);
  const [referralCode, setReferralCode] = useState("");
  const [BTCprice, setBTCPrice] = useState("");
  const [BNBprice, setBNBPrice] = useState("");
  const isValidUSDTamount = Number(USDTAmt) >= 20 || USDTAmt == "";

  useEffect(()=>{
    const fetchedbtcprice = async () =>{
      try{
        const response = await fetch ("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin&vs_currencies=usd");
         
        if(!response.ok){
          throw new Error("Response is not ok")
        }
        const result = await response.json();
        setBTCPrice(result.bitcoin.usd)
      }  catch(error){
        console.log(error)
      }
    }
    fetchedbtcprice()
  }, [])


  useEffect(()=>{
    const fetchedbnbprice = async () =>{
      try{
        const response = await fetch ("https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd");
         
        if(!response.ok){
          throw new Error("Response is not ok")
        }
        const result = await response.json();
        setBNBPrice(result.binancecoin.usd)
      }  catch(error){
        console.log(error)
      }
    }
    fetchedbnbprice()
  }, [])



  //read functions
  const address = useAddress();
  const { contract } = useContract(
    "0x7d2291988b4f8d3fed0c6aefa131c39586fcb88a"
  );
  const { data: cunWalletBal, isLoading: isCunWalletBalLoading } =
    useTokenBalance(contract, address);
  const { contract: USDTContract } = useContract(
    "0x55d398326f99059fF775485246999027B3197955"
  );
  const { data: walletBal, isLoading: walletBalLoading } = useTokenBalance(
    USDTContract,
    address
  );
  const { data: rewardAmt, isLoading: isRewardAmtLoading } = useContractRead(
    contract,
    "RewardAmount",
    [address]
  );
  const { data: parent, isLoading: isParentLoading } = useContractRead(
    contract,
    "parent",
    [address]
  );
  const { data: availableRewards, isLoading: isAvailableRewardsLoading } =
    useContractRead(contract, "getAvailableRewards", [address]);
  const { data: rewardLimit, isLoading: isRewardLimitLoading } =
    useContractRead(contract, "getRewardLimit", [address]);
  const { data: totalWithdrawn, isLoading: istotalWithdrawnLoading } =
    useContractRead(contract, "totalWithdrawn", [address]);
  const { data: tokenPrice, isLoading: isTokenPriceLoading } = useContractRead(
    contract,
    "TokenPrice",
    []
  );
  const { data: owner, isLoading: isOwnerLoading } = useContractRead(
    contract,
    "Owner",
    []
  );
  const { data: totalInvested, isLoading: istotalInvestedLoading } =
    useContractRead(contract, "totalInvested", [address]);
    
  const { data: directChild, isLoading: isDirectChildLoading } =
    useContractRead(contract, "showAllDirectChild", [address]);
  const { data: indirectChild, isLoading: isIndirectChildLoading } =
    useContractRead(contract, "showAllInDirectChild", [address]);
  const { data: userLevels, isLoading: isUserLevelsLoading } = useContractRead(
    contract,
    "userLevels",
    [address]
  );
  const { data: sellLimit, isLoading: isSellLimitlsLoading } = useContractRead(
    contract,
    "getSellingLimit",
    [address]
  );
  const { data: soldLimit, isLoading: isSoldLimitlsLoading } = useContractRead(
    contract,
    "totalAmountSold",
    [address]
  );
  const { data: poolLimit, isLoading: ispoolLimitlsLoading } = useContractRead(
    contract,
    "UsdtPool",
    []
  );
  const { data: supply, isLoading: issupplyLoading } = useContractRead(
    contract,
    "totalSupply",
    []
  );

  const { data: liverate, isLoading: isLiverateLoading } = useContractRead(
    contract,
    "TokenPrice",
    []
  );
  const { data: LPool, isLoading: isPoolLoading } = useContractRead(
    contract,
    "UsdtPool",
    []
  );

  //write funcs
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.forEach((value, key) => {
      setReferralCode(`${value}`);
    });
  }, []);

  console.log("Address", referralCode);

  //approve tokens
  const { mutateAsync: approve, isLoading: isApproveLoading } =
    useContractWrite(USDTContract, "approve");

    const handleCopyReferralLink = () => {
      if (referralLinkRef.current) {
        referralLinkRef.current.select();
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
    
        // Use react-toastify to display a toaster notification
        toast.success('Referral link copied to clipboard!', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    

  const approveTokens = async () => {
    try {
      setApproveTokensLoading(true);
      let spender = "0x7d2291988b4f8d3fed0c6aefa131c39586fcb88a"; //contract address
      let approveAmount = ethers.utils.parseEther(approveAmt);
      const data = await approve({ args: [spender, approveAmount] });
      console.info("contract call successs", data);
      toast.success("Successfully approved tokens!", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error("Approve Failed !", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("contract call failure", err);
    } finally {
      setApproveAmt("");
      setApproveTokensLoading(false);
    }
  };

  // buyTokens
  const { mutateAsync: BuyTokens, isLoading: isBuyTokensLoading } =
    useContractWrite(contract, "BuyTokens");

  const buyToken = async () => {
    setBuyTokenLoading(true);
    try {
      let ref;
      if (parent === "0x0000000000000000000000000000000000000000") {
        ref = referralCode;
      } else {
        ref = parent;
      }
      let usdtAmt = ethers.utils.parseEther(USDTAmt);

      console.log("usdtAmt",usdtAmt)

      const data = await BuyTokens({ args: [usdtAmt, ref] });
      console.info("contract call successs", data);
      toast.success("Tokens Bought Successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error(
        "You can not buy more than $1000 in one transaction",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
      console.error("contract call failure", err);


    } finally {

      setUSDTAmt("");
      setBuyTokenLoading(false);
    }
  };

  //sell Token
  const { mutateAsync: sellTokens, isLoading: isSellTokenLoading } =
    useContractWrite(contract, "sellTokens");

  const sellToken = async () => {
    try {
      setSellTokensLoading(true);
      let amount = ethers.utils.parseEther(cunAmt);
      const data = await sellTokens({ args: [amount] });
      console.info("contract call successs", data);
      toast.success("Tokens sold successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error("Selling amount exceeds limit", {
        position: toast.POSITION.TOP_CENTER,
      });

      console.error("contract call failure", err);
    } finally {
      setCunAmt("");
      setSellTokensLoading(false);
    }
  };

  //withdraw Tokens
  const { mutateAsync: withdraw, isLoading: isWithdrawTokensLoading } =
    useContractWrite(contract, "withdraw");

  const withdrawToken = async () => {
    try {
      setWithdrawTokensLoading(true);
      let amount = ethers.utils.parseEther(withdrawAmt);
      const data = await withdraw({ args: [amount] });
      console.info("contract call successs", data);
      toast.success("Tokens Has Been Successfully Withdrawn", {
        position: toast.POSITION.TOP_CENTER,
      });
    } catch (err) {
      toast.error("Tokens Withdraw Failed", {
        position: toast.POSITION.TOP_CENTER,
      });
      console.error("contract call failure", err);
    } finally {
      setWithdrawAmt("");
      setWithdrawTokensLoading(false);
    }
  };

  useEffect(() => {
    if (
      !walletBalLoading &&
      !isCunWalletBalLoading &&
      !isTokenPriceLoading &&
      !istotalWithdrawnLoading
    ) {
      console.log("contract : ", contract);
      console.log(address);
      console.log("usdtBal", walletBal);
      console.log("cun bal : ", cunWalletBal);
      console.log("token price : ", tokenPrice.toString());
      console.log("totalWithdrawn : ", totalWithdrawn.toString());
    }
    if (!isRewardAmtLoading) {
      console.log(rewardAmt.toString());
    }
    if (!isParentLoading) {
      console.log(parent);
    }
    if (
      !isAvailableRewardsLoading &&
      !isRewardLimitLoading &&
      !isOwnerLoading
    ) {
      console.log("rew limit : ", rewardLimit.toString());
      console.log("availableRewards : ", availableRewards.toString());
      console.log("owner", owner);
    }
  }, []);

  const handleShare = async () => {
    try {
      if (navigator.share) {
        const imagePath = "/Sharelink.png";
  
        const image = new Image();
        image.src = imagePath;
  
        image.onload = async () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          canvas.width = image.width;
          canvas.height = image.height;
  
          ctx.drawImage(image, 0, 0);
  
          const jsxElement = (
            <p className="price-live-rate" style={{ fontWeight: 'bold' }}>
              ${!isLiverateLoading
                ? parseFloat(ethers.utils.formatUnits(tokenPrice.toString())).toFixed(7)
                : "0.00"}
            </p>
          );
  
          const textString = renderToString(jsxElement);
          const text = new DOMParser().parseFromString(textString, 'text/html').body.textContent;
          ctx.font = "bold 30px Arial";
          ctx.fillStyle = "white";
          ctx.fillText(text, 635, 850);
  
          const currentDate = new Date().toLocaleDateString("en-US", {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
  
          ctx.font = "bold 30px Arial";
          ctx.fillStyle = "white";
          ctx.fillText(currentDate, 288, 850);
  
          canvas.toBlob(async (blob) => {
            const canvasImageFile = new File([blob], "referral.png", { type: "image/png" });
            await navigator.share({
              title: "Share your referral link",
              text: "Cunetwork.ai",
              url: `https://dashboard.cunetwork.ai/?ref=${address}`,
              files: [canvasImageFile],
            });
          }, "image/png");
        };
      } else {
        throw new Error("Web Share API not supported on this browser.");
      }
    } catch (error) {
      console.error("Error sharing:", error.message);
    }
  };
  
  
  return (
    <>
      {!address ? (
        <>
          <div className="">
            <div className="content">
              <ToastContainer />
              <div className="cunholder container">
                <div className="">
                  <h2>
                    Hello, <span style={{ color: "#4c53bd" }}>CUN</span> holder
                    !!
                  </h2>
                  <p>Dashboard overview</p>
                </div>
              </div>
              <div className="section1">
              <div className="container">
              <div className="row">
              <div className="col-lg-4 pr-lg-0">
                <div className="refarinfo3 refarinfo4 live-rate-mainbox mt-3">
                  <h4>CUN Live Rate</h4>
                  <p className="price-live-rate">
                     <img className="live-rate-logo" src="/cunrate.svg"/>
                    ${" "}
                    {!isLiverateLoading
                      ? parseFloat(
                          ethers.utils.formatUnits(tokenPrice.toString())
                        ).toFixed(7)
                      : "0.00"}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 pr-md-0 ">
                <div className="refarinfo3 refarinfo4 live-rate-mainbox mt-3">
                  <h4>Pool Liquidity</h4>
                  <p className="price-live-rate">
                  ${" "}
                    {!ispoolLimitlsLoading
                      ? parseFloat(
                          ethers.utils.formatUnits(poolLimit.toString())
                        ).toFixed(4)
                      : "0.00"}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 ">
                <div className="refarinfo3 refarinfo4 live-rate-mainbox mt-3">
                  <h4>Total Supply</h4>
                  <p className="price-live-rate">
                     {/* <img className="other-rate-logo" src="/bitcoin.svg"/> */}
                     {" "}
                    {!issupplyLoading
                      ? parseFloat(
                          ethers.utils.formatUnits(supply.toString())
                        ).toFixed(4)
                      : "0.00"} CUN
                  </p>
                </div>
              </div>
              </div>
              </div>
              </div>
              <div className="section2">
                <div className="container">
                  <div className="row">
                    {isParentLoading ? (
                      <div className="col-lg-4 mb-3 pr-md-0">
                        <div className="refarinfo3 refarinfo4">
                          <h4>
                            <span>
                              <img src={infoicon} alt="puricon" />
                            </span>
                            Share & Earn
                          </h4>

                          <input
                            className="link-copy-input"
                            type="text"
                            value={`https://dashboard.cunetwork.ai/?ref=${address}`}
                            ref={referralLinkRef}
                            readOnly
                          />

                          <div className="purch desktop_button_share">
                            <button onClick={handleCopyReferralLink}>
                              Share referral link
                            </button>
                          </div>

                          <div className="purch mobile_button_share">
                            <button onClick={handleShare}>
                              Share referral link
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : parent ===
                      "0x0000000000000000000000000000000000000000" ? (
                      <div className="col-lg-4 mb-3  pr-md-0">
                        <div className="refarinfo3 refarinfo4">
                          <h4>
                            <span>
                              <img src={infoicon} alt="puricon" />
                            </span>
                            Share & Earn
                          </h4>

                          <input
                            type="text"
                            className="link-copy-input"
                            value={`https://dashboard.cunetwork.ai/?ref=${address}`}
                            ref={referralLinkRef}
                            readOnly
                          />

                          <div className="purch desktop_button_share">
                            <button onClick={handleCopyReferralLink}>
                              Share referral link
                            </button>
                          </div>

                          <div className="purch mobile_button_share">
                            <button onClick={handleShare}>
                              Share referral link
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-4 mb-3  pr-md-0">
                        <div className="refarinfo3 refarinfo4">
                          <h4>
                            <span>
                              <img src={infoicon} alt="puricon" />
                            </span>
                            Share & Earn
                          </h4>

                          <input
                            type="text"
                            className="link-copy-input"
                            value={`https://dashboard.cunetwork.ai/?ref=${address}`}
                            ref={referralLinkRef}
                            readOnly
                          />

                          <div className="purch desktop_button_share">
                            <button onClick={handleCopyReferralLink}>
                              Share referral link
                            </button>
                          </div>

                          <div className="purch mobile_button_share">
                            <button onClick={handleShare}>
                              Share referral link
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="col-lg-4 mb-3  pr-md-0">
                      <div className="Level_Information">
                        <h2>Level Information</h2>
                        <div className="circle_Linformation">
                          {/* <h1>{!isUserLevelsLoading ? userLevels : 0}</h1> */}
                          {Array.from({ length: 10 }, (_, index) => (
                            <div className="circle_width" key={index}>
                              <div
                                className="Level_cricle_white"
                                style={{
                                  backgroundColor:
                                    index < userLevels ? "#18B28D" : "white",
                                }}
                              ></div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 mb-3">
                      <div className="widthdrow approve-token-new">
                        <h3 className="approve-heading">
                          Approve USDT{" "}
                          <span>
                            {/* <img src={widthdrow} alt="puricon" /> */}
                          </span>
                        </h3>

                        <div className="Available">
                          <input
                            value={approveAmt}
                            onChange={(e) => {
                              setApproveAmt(e.target.value);
                            }}
                            type="text"
                            placeholder="Enter USDT to approve"
                          />
                        </div>
                        <button
                          onClick={approveTokens}
                          className="approve_button"
                        >
                          {" "}
                          Approve USDT{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section3">
                <div className="container">
                  <div className="section3_chaid">
                    <div className="balance_info">
                      <p>
                        Available USDT
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <h4>
                        ${" "}
                        {walletBalLoading
                          ? "0.00"
                          : parseFloat(walletBal.displayValue).toFixed(2)}
                      </h4>
                    </div>

                    <div className="balance_info">
                      <p>
                        Referral Rewards{" "}
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <h4>$ 0.00</h4>
                    </div>

                    <div className="balance_info">
                      <p>
                        Available / Withdrawal{" "}
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <div className="withbalench">
                        <h4>$ 0.00 </h4> <span>/</span>
                        <h4>$ 0.00</h4>
                      </div>
                    </div>

                    <div className="balance_info mr-0">
                      <p>
                        Total withdrawn{" "}
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <h4>$ 0.00</h4>
                    </div>

                    {/* <div className="balance_info">
              <p>Withdrawal Limit <span className="greenarrow"><img src={greenarrow} alt="puricon" /></span></p>
              <h4>$5000.00</h4>
            </div> */}
                  </div>
                </div>
              </div>

              <div className="section3">
                <div className="container">
                  <div className="section3_chaid">
                    <div className="balance_info">
                      <p>
                        Total CUN Purchase
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <h4>$ 0.0</h4>
                    </div>

                    <div className="balance_info">
                      <p>
                        Direct / Total{" "}
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <div className="withbalench">
                        <h4> 0 </h4> <span>/</span>
                        <h4> 0</h4>
                      </div>
                    </div>

                    <div className="balance_info">
                      <p>
                        CUN Sold / Selling Limit{" "}
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <div className="withbalench">
                        <h4> 0 </h4> <span>/</span>
                        <h4> 0</h4>
                      </div>
                    </div>

                    <div className="balance_info  mr-0">
                      <p>
                        Available CUN{" "}
                        <span className="greenarrow">
                          <img src={greenarrow} alt="puricon" />
                        </span>
                      </p>
                      <h4>
                        {" "}
                        {isCunWalletBalLoading
                          ? "0.00"
                          : parseFloat(cunWalletBal.displayValue).toFixed(2)}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="section4 ">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-6 col-md-12 mb-3 padding_right">
                      <div className="purace">
                        <h3>
                          Purchase <span style={{ color: "#2B65FB" }}>CUN</span>{" "}
                          Tokens
                        </h3>
                        <h6>
                        NOTE : Buy CUN tokens in multiple of $20 and Max $1000 in one transaction.
                         
                        </h6>
                        {
                                  isValidUSDTamount ? null : <p className="price-warning-text text-danger text-xs">Price Should be 20 or Higher</p>
                                 }
                        <div className="price">
                       
                          <div className="price_right">
                          
                            <h4>
                              USDT Balance : ${" "}
                              {walletBalLoading
                                ? "0.00"
                                : parseFloat(walletBal.displayValue).toFixed(2)}
                            </h4>
                            <div className="rs">
                              <h5>
                                <input
                                  value={USDTAmt }
                                  onChange={(e) => {
                                    setUSDTAmt(e.target.value);
                                  }}
                                  type="number"
                                  placeholder="Enter USDT Amount"
                                />
                                 
                              </h5>
                            </div>
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="puricon" />
                          </div>
                          <div className="price_left">
                            <h4>Current rate : $ 0.00</h4>
                            <div className="wii_get">
                              <p>CUN </p>
                              <p>
                                <img src={victor} alt="puricon" />
                              </p>
                              <p>
                                {" "}
                                <b> 0.00</b>
                              </p>
                            </div>
                          </div>
                        </div>
                          <button className="purch">
                          Click to Buy{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>
                          
                        </button>  
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12 mb-3 padding_left">
                      <div className="purace">
                        <h3>
                          Sell <span style={{ color: "#2B65FB" }}>CUN</span>{" "}
                          Tokens
                        </h3>
                        <h6>
                          NOTE : Sell your tokens upto your selling limit (Max $1000 in one transaction)
                        </h6>

                        <div className="price">
                          <div className="price_right">
                            <h4>
                              CUN Balance:{" "}
                              {isCunWalletBalLoading
                                ? "0.00"
                                : parseFloat(cunWalletBal.displayValue).toFixed(
                                    2
                                  )}
                            </h4>
                            <div className="rs">
                              <h5>
                                <input
                                  value={cunAmt}
                                  type="number"
                                  onChange={(e) => {
                                    setCunAmt(e.target.value);
                                  }}
                                  placeholder="Enter CUN Amount"
                                />
                              </h5>
                            </div>
                            {/* <div className="Balance">
                      <p>Balance : 1200 USDT</p>
                      <h5> USDT<span> <img src={Ticon} alt="puricon" /></span></h5>
                    </div> */}
                          </div>
                          <div className="arrow">
                            <img src={arrow} alt="puricon" />
                          </div>
                          <div className="price_left">
                            <h4>Current rate : $ 0.00</h4>
                            <div className="wii_get">
                              <p>USDT </p>
                              <p>
                                <img src={victor} alt="puricon" />
                              </p>
                              <p>
                                {" "}
                                <b>0.00 </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        {/* <div className="reffer">
                  <p> Referrer <span className="hline">|</span> <span className="usericon"><i className="fa fa-user-plus" aria-hidden="true"></i></span>
                    <span className="container">0x8017Fc9a63F90A06eb0f74F23d7E3cB6341eB924</span></p>
                </div> */}

                        <button className="purch">
                          Click to sell{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>{" "}
                        </button>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                      <div className="widthdrow">
                        <h3>
                          Withdraw Rewards{" "}
                          <span>
                            <img src={widthdrow} alt="puricon" />
                          </span>
                        </h3>
                        <p>
                          NOTE : Withdraw rewards upto your limit
                        </p>

                        <div className="Available">
                          <p>Available rewards :$ 0.00 </p>
                          <input
                            value={withdrawAmt}
                            onChange={(e) => {
                              setWithdrawAmt(e.target.value);
                            }}
                            type="text"
                            placeholder="Enter amount to withdraw"
                          />
                        </div>

                        <h6>
                          NOTE : 10% liquidity pool, 5% referral pool, 5% admin
                        </h6>

                        <button>
                          {" "}
                          Withdraw rewards{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="balance_info">
              <p>
                Available USDT
                <span className="greenarrow">
                  <img src={greenarrow} alt="puricon" />
                </span>
              </p>
              <h4>
                ${" "}
                {walletBalLoading
                  ? "0.00"
                  : parseFloat(walletBal.displayValue).toFixed(2)}
              </h4>
            </div> */}
          </div>
        </>
      ) : (
        <div>
          <div className="content">
            <ToastContainer />
            <div className="cunholder container">
              <div className="container pl-0">
                <h2>
                  Hello, <span style={{ color: "#4c53bd" }}>CUN</span> holder !!
                </h2>
                <p>Dashboard overview</p>
                {/* <h1>{!isUserLevelsLoading ? userLevels : 0}</h1> */}
              </div>
            </div>
            <div className="section1">
              <div className="container">
              <div className="row">
              <div className="col-lg-4 pr-md-0">
                <div className="refarinfo3 refarinfo4 live-rate-mainbox mt-3">
                  <h4>CUN Live Rate</h4>
                  <p className="price-live-rate">
                  <img className="live-rate-logo" src="/cunrate.svg"/>
                    ${" "}
                    {!isLiverateLoading
                      ? parseFloat(
                          ethers.utils.formatUnits(tokenPrice.toString())
                        ).toFixed(7)
                      : "0.00"}
                  </p>
                </div>
              </div>
              <div className="col-lg-4 pr-md-0">
                <div className="refarinfo3 refarinfo4 live-rate-mainbox mt-3">
                  <h4>Pool Liquidity</h4>
                  <p className="price-live-rate">
                  {/* <img className="other-rate-logo" src="/binance.svg"/> */}
                  ${" "}
                    {!ispoolLimitlsLoading
                      ? parseFloat(
                          ethers.utils.formatUnits(poolLimit.toString())
                        ).toFixed(4)
                      : "0.00"}
                  </p>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="refarinfo3 refarinfo4 live-rate-mainbox mt-3">
                <h4>Total Supply</h4>
                  <p className="price-live-rate">
                     {/* <img className="other-rate-logo" src="/bitcoin.svg"/> */}
                     {" "}
                    {!issupplyLoading
                      ? parseFloat(
                          ethers.utils.formatUnits(supply.toString())
                        ).toFixed(4)
                      : "0.00"} CUN
                  </p>
                </div>
              </div>
            </div>

              </div>
              </div>

            <div className="section2">
              <div className="container">
                <div className="row">
                  {/* <div className="col-lg-4">
                      <div className="refarinfo2">
                        <p>To activate your ID and get benifits, you must make a
                          purchase of minimum 10$. For every 10$, your level
                          will be unlocked.</p>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="refarinfo2">
                        <p>You can increase your withdrawal limit by 5X
                          of the invested amount on every purchase.</p>
                        <a href='#'>Read whitepaper <span ><i className="fa fa-long-arrow-right" aria-hidden="true"></i></span></a>
                      </div>
                    </div> */}
                  {isParentLoading ? (
                    <div className="col-lg-4 mb-3  pr-md-0">
                      <div className="refarinfo3 refarinfo4">
                        <h4>
                          <span>
                            <img src={infoicon} alt="puricon" />
                          </span>
                          Share & Earn
                        </h4>

                        <input
                          type="text"
                          className="link-copy-input"
                          value="https://dashboard.cunetwork.ai/-"
                          ref={referralLinkRef}
                          readOnly
                        />

                        <div className="purch desktop_button_share">
                          <button onClick={handleCopyReferralLink}>
                            Share referral link
                          </button>
                        </div>

                        <div className="purch mobile_button_share">
                          <button onClick={handleShare}>
                            Share referral link
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : parent ===
                    "0x0000000000000000000000000000000000000000" ? (
                    <div className="col-lg-4 mb-3  pr-md-0">
                      <div className="refarinfo3 refarinfo4">
                        <h4>
                          <span>
                            <img src={infoicon} alt="puricon" />
                          </span>
                          Share & Earn
                        </h4>

                        <input
                          type="text"
                          className="link-copy-input"
                          value={`https://dashboard.cunetwork.ai/?ref=${address}`}
                          ref={referralLinkRef}
                          readOnly
                        />

                        <div className="purch desktop_button_share">
                          <button onClick={handleCopyReferralLink}>
                            Share referral link
                          </button>
                        </div>

                        <div className="purch mobile_button_share">
                          <button onClick={handleShare}>
                            Share referral link
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="col-lg-4 mb-3  pr-md-0">
                      <div className="refarinfo3 refarinfo4">
                        <h4>
                          <span>
                            <img src={infoicon} alt="puricon" />
                          </span>
                          Share & Earn
                        </h4>

                        <input
                          type="text"
                          className="link-copy-input"
                          //

                          value={`https://dashboard.cunetwork.ai/?ref=${address}`}
                          ref={referralLinkRef}
                          readOnly
                        />

                        <div className="purch desktop_button_share">
                          <button onClick={handleCopyReferralLink}>
                            Share referral link
                          </button>
                        </div>

                        <div className="purch mobile_button_share">
                          <button onClick={handleShare}>
                            Share referral link
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="col-lg-4 mb-3  pr-md-0">
                    <div className="Level_Information">
                      <h2>Level Information</h2>
                      <div className="circle_Linformation">
                        {/* <h1>{!isUserLevelsLoading ? userLevels : 0}</h1> */}
                        {Array.from({ length: 10 }, (_, index) => (
                          <div className="circle_width" key={index}>
                            <div
                              className="Level_cricle_white"
                              style={{
                                backgroundColor:
                                  index < userLevels ? "#5d67f8ba" : "white", 
                              }}
                            ></div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 mb-3">
                    <div className="widthdrow approve-token-new">
                      <h3 className="approve-heading">
                        Approve USDT{" "}
                        <span>
                          {/* <img src={widthdrow} alt="puricon" /> */}
                        </span>
                      </h3>

                      <div className="Available">
                        <input
                          value={approveAmt}
                          onChange={(e) => {
                            setApproveAmt(e.target.value);
                          }}
                          type="text"
                          placeholder="Enter USDT to approve"
                        />
                      </div>
                      <button
                        onClick={approveTokens}
                        className="approve_button"
                      >
                        {" "}
                        Approve USDT{" "}
                        <span className="btnicon">
                          <img src={greenarrow} alt="puricon" />
                        </span>{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="section3">
              <div className="container">
                <div className="section3_chaid">
                  <div className="balance_info">
                    <p>
                      Available USDT
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <h4>
                      ${" "}
                      {walletBalLoading
                        ? "0.00"
                        : parseFloat(walletBal.displayValue).toFixed(2)}
                    </h4>
                  </div>

                  <div className="balance_info">
                    <p>
                      Referral Rewards{" "}
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <h4>
                      ${" "}
                      {!isRewardAmtLoading
                        ? parseFloat(
                            ethers.utils.formatUnits(rewardAmt.toString())
                          ).toFixed(2)
                        : "0.00"}
                    </h4>
                  </div>

                  <div className="balance_info">
                    <p>
                      Available / Withdrawal{" "}
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <div className="withbalench">
                      <h4>
                        ${" "}
                        {!isAvailableRewardsLoading
                          ? parseFloat(
                              ethers.utils.formatEther(
                                availableRewards.toString()
                              )
                            ).toFixed(2)
                          : "0.00"}{" "}
                      </h4>{" "}
                      <span>/</span>
                      <h4>
                        ${" "}
                        {!isRewardLimitLoading
                          ? parseFloat(
                              ethers.utils.formatEther(rewardLimit.toString())
                            ).toFixed(2)
                          : "0.00"}
                      </h4>
                    </div>
                  </div>

                  <div className="balance_info mr-0">
                    <p>
                      Total withdrawn{" "}
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <h4>
                      ${" "}
                      {!istotalWithdrawnLoading
                        ? parseFloat(
                            ethers.utils.formatEther(totalWithdrawn.toString())
                          ).toFixed(2)
                        : "0.00"}
                    </h4>
                  </div>

                  {/* <div className="balance_info">
              <p>Withdrawal Limit <span className="greenarrow"><img src={greenarrow} alt="puricon" /></span></p>
              <h4>$5000.00</h4>
            </div> */}
                </div>
              </div>
            </div>

            <div className="section3">
              <div className="container">
                <div className="section3_chaid">
                  <div className="balance_info">
                    <p>
                      Total CUN Purchase
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <h4>
                      ${" "}
                      {!istotalInvestedLoading
                        ? parseFloat(
                            ethers.utils.formatEther(totalInvested.toString())
                          ).toFixed(2)
                        : "0.0"}
                    </h4>
                  </div>

                  <div className="balance_info">
                    <p>
                      Direct team / Total team{" "}
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <div className="withbalench">
                      <h4>{!isDirectChildLoading ? directChild.length : 0}</h4>
                      <span>/</span>
                      <h4>
                        {!isIndirectChildLoading && !isDirectChildLoading
                          ? indirectChild.length + directChild.length
                          : 0}
                      </h4>
                    </div>
                  </div>

                  <div className="balance_info">
                    <p>
                      CUN Sold / Selling Limit{" "}
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <div className="withbalench">
                      <h4>
                        ${" "}
                        {!isSoldLimitlsLoading
                          ? parseFloat(
                              ethers.utils.formatUnits(soldLimit.toString())
                            ).toFixed(2)
                          : "0.00"}
                      </h4>
                      <span>/</span>
                      <h4>
                        ${" "}
                        {!isSellLimitlsLoading
                          ? parseFloat(
                              ethers.utils.formatUnits(sellLimit.toString())
                            ).toFixed(2)
                          : "0.00"}
                      </h4>
                    </div>
                  </div>

                  <div className="balance_info mr-0">
                    <p>
                      Available CUN{" "}
                      <span className="greenarrow">
                        <img src={greenarrow} alt="puricon" />
                      </span>
                    </p>
                    <h4>
                      {" "}
                      {isCunWalletBalLoading
                        ? "0.00"
                        : parseFloat(cunWalletBal.displayValue).toFixed(2)}
                    </h4>
                  </div>

                  {/* <div className="balance_info">
              <p>Withdrawal Limit <span className="greenarrow"><img src={greenarrow} alt="puricon" /></span></p>
              <h4>$5000.00</h4>
            </div> */}
                </div>
              </div>
            </div>

            <div className="section4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 col-md-12 mb-3  padding_right">
                    <div className="purace">
                      <h3>
                        Purchase <span style={{ color: "#2B65FB" }}>CUN</span>{" "}
                        Tokens
                      </h3>
                      <h6>
                        NOTE : Buy CUN tokens in multiple of $20 and Max $1000 in one transaction.
                       
                      </h6>
                      {       isValidUSDTamount ? null : <p className="price-warning-text text-danger text-xs">Price Should be 20 or Higher</p>
                        }
                      <div className="price">
                        <div className="price_right">
                          <h4>
                            USDT Balance : ${" "}
                            {walletBalLoading
                              ? "0.00"
                              : parseFloat(walletBal.displayValue).toFixed(2)}
                          </h4>
                          <div className="rs">
                            <h5>
                              <input
                                value={USDTAmt}
                                onChange={(e) => {
                                  setUSDTAmt(e.target.value);
                                }}
                                type="number"
                                placeholder="Enter USDT Amount"
                              />
                            </h5>
                          </div>
                          {/* <div className="Balance">
                      <p>Balance : 1200 USDT</p>
                      <h5> USDT<span> <img src={Ticon} alt="puricon" /></span></h5>
                    </div> */}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="puricon" />
                        </div>
                        <div className="price_left">
                          <h4>
                            Current rate : ${" "}
                            {!isTokenPriceLoading
                              ? parseFloat(
                                  ethers.utils.formatEther(
                                    tokenPrice.toString()
                                  )
                                ).toFixed(7)
                              : "0.00"}
                          </h4>
                          <div className="wii_get">
                            <p>CUN </p>
                            <p>
                              <img src={victor} alt="puricon" />
                            </p>
                            <p>
                              {" "}
                              <b>
                                {" "}
                                {!isTokenPriceLoading
                                  ? (
                                      USDTAmt /
                                      parseFloat(
                                        ethers.utils.formatEther(
                                          tokenPrice.toString()
                                        )
                                      ) /
                                      2
                                    ).toFixed(2)
                                  : "0.00"}{" "}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="reffer">
                  <p> Referrer <span className="hline">|</span> <span className="usericon"><img src={referr} alt="puricon" /></span>
                    <span className="container">0x8017Fc9a63F90A06eb0f74F23d7E3cB6341eB924</span></p>
                </div> */}

                      {BuyTokenLoading ? (
                        <button className="purch">
                          {" "}
                          <span className="btnicon"></span>
                          <Bars
                            height="20"
                            width="1000"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button onClick={buyToken} className="purch usdt-buy-button" disabled={USDTAmt < 20}>
                          Click to Buy{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 mb-3 padding_left">
                    <div className="purace">
                      <h3>
                        Sell <span style={{ color: "#2B65FB" }}>CUN</span> Tokens
                      </h3>
                      <h6> NOTE : Sell your tokens upto your selling limit (Max $1000 in one transaction)</h6>

                      <div className="price">
                        <div className="price_right">
                          <h4>
                            CUN Balance:{" "}
                            {isCunWalletBalLoading
                              ? "0.00"
                              : parseFloat(cunWalletBal.displayValue).toFixed(
                                  2
                                )}
                          </h4>
                          <div className="rs">
                            <h5>
                              <input
                                value={cunAmt}
                                type="number"
                                onChange={(e) => {
                                  setCunAmt(e.target.value);
                                }}
                                placeholder="Enter CUN Amount"
                              />
                            </h5>
                          </div>
                          {/* <div className="Balance">
                      <p>Balance : 1200 USDT</p>
                      <h5> USDT<span> <img src={Ticon} alt="puricon" /></span></h5>
                    </div> */}
                        </div>
                        <div className="arrow">
                          <img src={arrow} alt="puricon" />
                        </div>
                        <div className="price_left">
                          <h4>
                            Current rate : ${" "}
                            {!isTokenPriceLoading
                              ? parseFloat(
                                  ethers.utils.formatEther(
                                    tokenPrice.toString()
                                  )
                                ).toFixed(7)
                              : "0.00"}
                          </h4>
                          <div className="wii_get">
                            <p>USDT </p>
                            <p>
                              <img src={victor} alt="puricon" />
                            </p>
                            <p>
                              {" "}
                              <b>
                                {!isTokenPriceLoading
                                  ? (
                                      parseFloat(
                                        ethers.utils.formatEther(
                                          tokenPrice.toString()
                                        )
                                      ) * cunAmt
                                    ).toFixed(10)
                                  : "0.00"}{" "}
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <div className="reffer">
                  <p> Referrer <span className="hline">|</span> <span className="usericon"><i className="fa fa-user-plus" aria-hidden="true"></i></span>
                    <span className="container">0x8017Fc9a63F90A06eb0f74F23d7E3cB6341eB924</span></p>
                </div> */}

                      {SellTokensloading ? (
                        <button className="purch">
                          {" "}
                          <span className="btnicon"></span>
                          <Bars
                            height="20"
                            width="1000"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button onClick={sellToken} className="purch">
                          Click to Sell{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-12 ">
                    <div className="widthdrow">
                      <h3>
                        Withdraw Rewards{" "}
                        <span>
                          {/* <img src={widthdrow} alt="puricon" /> */}
                        </span>
                      </h3>
                      <p>
                        NOTE : Withdraw rewards upto your limit
                      </p>

                      <div className="Available">
                        <p>
                          Available rewards :${" "}
                          {!isAvailableRewardsLoading
                            ? parseFloat(
                                ethers.utils.formatEther(
                                  availableRewards.toString()
                                )
                              ).toFixed(2)
                            : "0.00"}{" "}
                        </p>
                        <input
                          value={withdrawAmt}
                          onChange={(e) => {
                            setWithdrawAmt(e.target.value);
                          }}
                          type="text"
                          placeholder="Enter amount to withdraw"
                        />
                      </div>

                      <h6>
                        NOTE : 10% liquidity pool, 5% referral pool, 5% admin
                      </h6>

                      {WithdrawTokensloading ? (
                        <button className="purch">
                          {" "}
                          <span className="btnicon"></span>
                          <Bars
                            height="20"
                            width="1000"
                            color="#fff"
                            ariaLabel="bars-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </button>
                      ) : (
                        <button onClick={withdrawToken} className="purch">
                          Click to withdraw{" "}
                          <span className="btnicon">
                            <img src={greenarrow} alt="puricon" />
                          </span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Deshbord;
